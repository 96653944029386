

import React,{useState,useEffect} from 'react';
import './OrderConfirm.css';
import { useNavigate ,useLocation} from 'react-router-dom';
import PaymentStatusModal from './../../Components/Modal/PaymentConfirmModal/PaymentConfirmModal';
import { useOrderContext } from './../../Context/ContextStore';
import InfoModal from './../../Components/Modal/SummeryModal/CostSummary';
import { FaInfoCircle } from 'react-icons/fa'; 
import { FaArrowLeft } from "react-icons/fa";

const ConfirmOrder = () => {
 
  const location = useLocation();
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const { totalAmount, baseFare, extraFarePerKm, weight,distance,weightFare,additionalcharges } = location.state || {};
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [token, setToken] = useState(null);
  // const [forceUpdate, setForceUpdate] = useState(false); // New state to force re-render

  
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    // Check if token exists in local storage
    const storedToken = localStorage.getItem('authToken');
    setToken(storedToken);
}, []);

// useEffect(() => {
//   // Trigger re-render when loading changes
// }, [forceUpdate]);


  const { orderData,updateOrderData ,isScheduled} = useOrderContext();
  const pricingInfo = {
    baseFare:baseFare,
    totalAmount:totalAmount,
    extraFarePerKm:extraFarePerKm,
    distance:distance,
    weightFare:weightFare,
    additionalcharge:additionalcharges
  };

  

  const loadScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        console.log('Razorpay script loaded successfully');
        resolve(true);
      };
      script.onerror = () => {
        console.error('Failed to load Razorpay script');
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const submitOrder = async () => {
    if(!token){
       navigate('/login')
       return
      
    }

    setLoading(true)
    const orderDetails = {
      serviceType: isScheduled ? "Schedule for Later" : "Delivery Now",
      name: orderData.senderName,
      phoneNumber: orderData.senderPhone,
      senderAddress: orderData.senderAddress,
      receiverAddress: orderData.receiverAddress,
      email: orderData.senderEmail,
      weight: orderData.contentWeight,
      pickupAddress: orderData.pickupAddress,
      dropAddress: orderData.dropAddress,
      content: orderData.packageContent,
      deliveryInstructions: orderData.instructions,
      receiverPhonenumber: orderData.receiverPhone,
      receiverName: orderData.receiverName,
      pickupDate: isScheduled ? orderData.pickupDate : null, // only for 'Schedule for Later'
      pickupTime: isScheduled ? orderData.pickupTime : null  // only for 'Schedule for Later'
    };

       console.log(orderDetails);
    

    try {
      const amountInPaise = parseFloat(totalAmount.replace('₹', '').trim()) * 100;

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/data/create-order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: amountInPaise, currency: "INR"}),
      }).catch(error => {
        console.error('Fetch error:', error);
        throw new Error('Failed to fetch');
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Error creating Razorpay order:', errorResponse);
        throw new Error(`Failed to create order: ${response.statusText}`);
      }

      const razorpayOrder = await response.json().catch(error => {
        console.error('Error parsing JSON:', error);
        throw new Error('Failed to parse JSON response');
      });

      console.log("Razorpay order created:", razorpayOrder);

      const res = await loadScript();

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const options = {
        key: 'rzp_test_8zzUM5gDGKZ1Ie',
        amount: razorpayOrder.amount,
        currency: razorpayOrder.currency,
        name: "TURTU India Llp",
        description: "Delivery Payment",
        order_id: razorpayOrder.id,
        handler: async (response) => {
          try {
            const paymentResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/data/usersubmit`, {
              method: 'POST',
              headers: {
                 'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, 
                },
              body: JSON.stringify({
                ...orderDetails,
             
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                amount: razorpayOrder.amount
              }),
            });

            console.log(orderDetails);
            

            const textResponse = await paymentResponse.text();
            console.log('Raw server response:', textResponse);

            if (!paymentResponse.ok) {
              throw new Error("Network response was not ok: " + textResponse);
            }

            let paymentData;
            try {
              paymentData = JSON.parse(textResponse);
            } catch (parseError) {
              console.error('Error parsing JSON:', parseError);
              throw new Error('Received response is not in JSON format.');
            }

            console.log(paymentData);
           

            setPaymentSuccess(true);
            setLoading(false)
            setModalVisible(true);
           
          } catch (err) {
            console.error('Error processing payment:', err);
            alert('There was an error processing your payment.');
            setLoading(false)
          }
        },
        prefill: {
          name: orderDetails.name,
          email: orderDetails.email,
          contact: orderDetails.phoneNumber,
        },
        modal: {
          ondismiss: () => {
            console.log('Razorpay popup closed');
            setLoading(false); // Stop loading when Razorpay modal is closed
            alert('Payment process was cancelled.');
          }
        }
       
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      
      updateOrderData({
        senderPhone: '',
        senderName: '',
        senderEmail: '',
        pickupAddress: '',
        receiverName: '',
        receiverPhone: '',
        senderAddress:'',
        receiverAddress:'',
        dropAddress: '',
        contentWeight: 1,
        packageContent: '',
        pickupDate: null,
        pickupTime: null,
        instructions: '',
        distance: '',
      });
    

    } catch (error) {
      console.error('Error creating Razorpay order:', error);
      alert('There was an error creating your order.');
      setPaymentSuccess(false);
      setLoading(false);
      setModalVisible(true);
    }
  };

  // const loadScript = () => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = "https://checkout.phonepe.com/v1/checkout.js"; // Replace this with the correct URL from PhonePe's documentation
  //     script.onload = () => {
  //       console.log('PhonePe script loaded successfully');
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       console.error('Failed to load PhonePe script');
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };

  // const submitOrder = async () => {
  //   if(!token){
  //     navigate('/login');
  //     return;
  //   }
  
  //   setLoading(true);
  //   const orderDetails = {
  //     serviceType: isScheduled ? "Schedule for Later" : "Delivery Now",
  //     name: orderData.senderName,
  //     phoneNumber: orderData.senderPhone,
  //     senderAddress: orderData.senderAddress,  
  //     receiverAddress: orderData.receiverAddress,
  //     email: orderData.senderEmail,
  //     weight: orderData.contentWeight,
  //     pickupAddress: orderData.pickupAddress,
  //     dropAddress: orderData.dropAddress,
  //     content: orderData.packageContent,
  //     deliveryInstructions: orderData.instructions,
  //     receiverPhonenumber: orderData.receiverPhone,
  //     receiverName: orderData.receiverName,
  //     pickupDate: isScheduled ? orderData.pickupDate : null, // only for 'Schedule for Later'
  //     pickupTime: isScheduled ? orderData.pickupTime : null  // only for 'Schedule for Later'
  //   };
  
  //   try {
  //     const amountInPaise = parseFloat(totalAmount.replace('₹', '').trim()) * 100;
  
  //     const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/data/create-order`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ amount: amountInPaise, currency: "INR"}), // Adjust the request payload as required
  //     });
  
  //     if (!response.ok) {
  //       const errorResponse = await response.text();
  //       console.error('Error creating PhonePe order:', errorResponse);
  //       throw new Error(`Failed to create order: ${response.statusText}`);
  //     }
  
  //     const phonePeOrder = await response.json();
  
  //     console.log("PhonePe order created:", phonePeOrder);
  
  //     const res = await loadScript();
  
  //     if (!res) {
  //       alert("PhonePe SDK failed to load. Are you online?");
  //       return;
  //     }
  
  //     const options = {
  //       env: "TEST", // Set to "TEST" for testing, "PROD" for production
  //       clientId: "TURTUINDIALLPUAT", // Your PhonePe client ID
  //       orderId: phonePeOrder.orderId, // Order ID generated from your backend
  //       amount: phonePeOrder.amount, // Amount in paise
  //       merchantId: phonePeOrder.merchantId, // Merchant ID
  //       message: "Payment for your order",
  //       redirectUrl: "YOUR_REDIRECT_URL", // URL to redirect after payment
  //       showPayButton: true,
  //     };
  
  //     const phonePe = new window.PhonePe(options);
  
  //     phonePe.on("onSuccess", async (response) => {
  //       try {
  //         const paymentResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/data/usersubmit`, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': `Bearer ${token}`, 
  //           },
  //           body: JSON.stringify({
  //             ...orderDetails,
  //             phonepe_payment_id: response.paymentId,
  //             amount: phonePeOrder.amount
  //           }),
  //         });
  
  //         const textResponse = await paymentResponse.text();
  //         console.log('Raw server response:', textResponse);
  
  //         if (!paymentResponse.ok) {
  //           throw new Error("Network response was not ok: " + textResponse);
  //         }
  
  //         let paymentData;
  //         try {
  //           paymentData = JSON.parse(textResponse);
  //         } catch (parseError) {
  //           console.error('Error parsing JSON:', parseError);
  //           throw new Error('Received response is not in JSON format.');
  //         }
  
  //         console.log(paymentData);
  
  //         setPaymentSuccess(true);
  //         setLoading(false);
  //         setModalVisible(true);
  //       } catch (err) {
  //         console.error('Error processing payment:', err);
  //         alert('There was an error processing your payment.');
  //         setLoading(false);
  //       }
  //     });
  
  //     phonePe.on("onError", (error) => {
  //       console.error('Error during PhonePe payment:', error);
  //       alert('There was an error with your payment.');
  //       setLoading(false);
  //     });
  
  //     phonePe.open();
  
  //     updateOrderData({
  //       senderPhone: '',
  //       senderName: '',
  //       senderEmail: '',
  //       pickupAddress: '',
  //       receiverName: '',
  //       receiverPhone: '',
  //       senderAddress:'',
  //       receiverAddress:'',
  //       dropAddress: '',
  //       contentWeight: 1,
  //       packageContent: '',
  //       pickupDate: null,
  //       pickupTime: null,
  //       instructions: '',
  //       distance: '',
  //     });
  
  //   } catch (error) {
  //     console.error('Error creating PhonePe order:', error);
  //     alert('There was an error creating your order.');
  //     setPaymentSuccess(false);
  //     setLoading(false);
  //     setModalVisible(true);
  //   }
  // };


  // const submitOrder = async () => {
  //   if (!token) {
  //     navigate('/login');
  //     return;
  //   }
  
  //   setLoading(true);
  //   const orderDetails = {
  //     serviceType: isScheduled ? "Schedule for Later" : "Delivery Now",
  //     name: orderData.senderName,
  //     phoneNumber: orderData.senderPhone,
  //     senderAddress: orderData.senderAddress,
  //     receiverAddress: orderData.receiverAddress,
  //     email: orderData.senderEmail,
  //     weight: orderData.contentWeight,
  //     pickupAddress: orderData.pickupAddress,
  //     dropAddress: orderData.dropAddress,
  //     content: orderData.packageContent,
  //     deliveryInstructions: orderData.instructions,
  //     receiverPhonenumber: orderData.receiverPhone,
  //     receiverName: orderData.receiverName,
  //     pickupDate: isScheduled ? orderData.pickupDate : null,
  //     pickupTime: isScheduled ? orderData.pickupTime : null
  //   };
  
  //   console.log(orderDetails);
  
  //   try {
  //     const amountInPaise = parseFloat(totalAmount.replace('₹', '').trim()) * 100;
  
  //     const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/data/create-phonepe-order`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ amount: amountInPaise, currency: "INR" }),
  //     });
  
  //     if (!response.ok) {
  //       const errorResponse = await response.text();
  //       console.error('Error creating PhonePe order:', errorResponse);
  //       throw new Error(`Failed to create order: ${response.statusText}`);
  //     }
  
  //     const phonePeOrder = await response.json();
  
  //     console.log("PhonePe order created:", phonePeOrder);

     
  
  //     const res = await loadScript();
  
  //     if (!res) {
  //       alert("PhonePe SDK failed to load. Are you online?");
  //       return;
  //     }
  
  //     const options = {
  //       env: "TEST", // Set to "TEST" for testing, "PROD" for production
  //       clientId: "TURTUINDIALLPUAT", // Your PhonePe client ID
  //       orderId: phonePeOrder.orderId, // Order ID generated from your backend
  //       amount: phonePeOrder.amount, // Amount in paise
  //       merchantId: phonePeOrder.merchantId, // Merchant ID
  //       message: "Payment for your order",
  //       showPayButton: true,
  //       callbackUrl: "http://localhost:3000" // Dummy URL, not used since we're handling the response directly
  //     };
  
  //     const phonePe = new window.PhonePe(options);
  //     phonePe.onSuccess = async (response) => {
  //       try {
  //         const paymentResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/data/usersubmit`, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             ...orderDetails,
  //             phonepe_payment_id: response.paymentId,
  //             phonepe_order_id: response.orderId,
  //             phonepe_signature: response.signature,
  //             amount: phonePeOrder.amount
  //           }),
  //         });
  
  //         const textResponse = await paymentResponse.text();
  //         console.log('Raw server response:', textResponse);
  
  //         if (!paymentResponse.ok) {
  //           throw new Error("Network response was not ok: " + textResponse);
  //         }
  
  //         let paymentData;
  //         try {
  //           paymentData = JSON.parse(textResponse);
  //         } catch (parseError) {
  //           console.error('Error parsing JSON:', parseError);
  //           throw new Error('Received response is not in JSON format.');
  //         }
  
  //         console.log(paymentData);
  
  //         setPaymentSuccess(true);
  //         setLoading(false);
  //         setModalVisible(true);
  
  //       } catch (err) {
  //         console.error('Error processing payment:', err);
  //         alert('There was an error processing your payment.');
  //         setLoading(false);
  //       }
  //     };
  
  //     phonePe.onFailure = (response) => {
  //       console.error('Payment failed:', response);
  //       alert('Payment failed. Please try again.');
  //       setLoading(false);
  //     };
  
  //     phonePe.open();
  
  //     updateOrderData({
  //       senderPhone: '',
  //       senderName: '',
  //       senderEmail: '',
  //       pickupAddress: '',
  //       receiverName: '',
  //       receiverPhone: '',
  //       senderAddress: '',
  //       receiverAddress: '',
  //       dropAddress: '',
  //       contentWeight: 1,
  //       packageContent: '',
  //       pickupDate: null,
  //       pickupTime: null,
  //       instructions: '',
  //       distance: '',
  //     });
  
  //   } catch (error) {
  //     console.error('Error creating PhonePe order:', error);
  //     alert('There was an error creating your order.');
  //     setPaymentSuccess(false);
  //     setLoading(false);
  //     setModalVisible(true);
  //   }
  // };
  
  
  


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleRetry = () => {
    setModalVisible(false);
    navigate(-1);
  };

  const handleReturnHome = () => {
    setModalVisible(false);
    navigate('/', { replace: true });
  };

  const handleBackClick = () => {
    navigate('/pick-and-drop');
  };


  return (
<div className='oM-ConfirmOrder'>
<button className="back-button" onClick={handleBackClick}>
            <FaArrowLeft /> Back
          </button>

        <div className="oM-container">
          <h1 className="oM-heading">Confirm Your Order</h1>
          <div className="oM-detailBox">
            <span className="oM-label">Pickup Address:</span>
            <span className="oM-value">{ orderData.pickupAddress}</span>
          </div>
          <div className="oM-detailBox">
            <span className="oM-label">Drop Address:</span>
            <span className="oM-value">{orderData.dropAddress}</span>
          </div>
          <div className="oM-detailBox">
            <span className="oM-label">Contents:</span>
            <span className="oM-value">{orderData.packageContent}</span>
          </div>
          <div className="oM-detailBox">
            <span className="oM-label">Weight:</span>
            <span className="oM-value">{weight} kg</span>
          </div>
          <div className="oM-detailBox">
            <span className="oM-label">Distance:</span>
            <span className="oM-value">{distance}</span>
          </div>
          <div className="oM-totalBox">
            <span className="oM-totalLabel" onClick={toggleModal}>Total Cost   
            <span className="oM-infoIcon" onClick={toggleModal}> <FaInfoCircle/></span>
            </span>
            <span className="oM-totalValue">{totalAmount}
           
            </span>
          </div>
            <button 
              onClick={submitOrder} 
              className="oM-payNowButton"
              // disabled={!token}
            >
               {loading ? 'Processing...' : (token ? 'Pay Now' : 'Sign Up')}
            {/* {token ? 'Pay Now' : 'Sign Up / Pay Now'} */}
            </button>
          < InfoModal isOpen={isModalOpen} onClose={toggleModal} pricingInfo={pricingInfo} />
          <PaymentStatusModal
            isOpen={modalVisible}
            onRequestClose={() => setModalVisible(false)}
            paymentSuccess={paymentSuccess}
            handleRetry={handleRetry}
            handleReturnHome={handleReturnHome}
          />

        </div>
        </div>
      );

};



export default ConfirmOrder;