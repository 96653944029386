import React, { useRef } from "react";
import './Shippingpolicy.css';

const ShippingPolicy = () => {
  const deliveryAreasRef = useRef(null);
  const deliveryTimingsRef = useRef(null);
  const deliveryChargesRef = useRef(null);
  const orderTrackingRef = useRef(null);
  const packagingRef = useRef(null);
  const deliveryAttemptRef = useRef(null);
  const restrictedItemsRef = useRef(null);
  const cancellationsRef = useRef(null);
  const delayedDeliveriesRef = useRef(null);
  const refundPolicyRef = useRef(null);
  const customerSupportRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="sdcp-container">
      <div className="sdcp-sidebar">
        <h2 className="sdcp-sidebar-heading">Table of Contents</h2>
        <ul className="sdcp-sidebar-list">
          <li><button onClick={() => scrollToSection(deliveryAreasRef)} className="sdcp-sidebar-heading">Delivery Areas</button></li>
          <li><button onClick={() => scrollToSection(deliveryTimingsRef)} className="sdcp-sidebar-heading">Delivery Timings</button></li>
          <li><button onClick={() => scrollToSection(deliveryChargesRef)} className="sdcp-sidebar-heading">Delivery Charges</button></li>
          <li><button onClick={() => scrollToSection(orderTrackingRef)} className="sdcp-sidebar-heading">Order Tracking</button></li>
          <li><button onClick={() => scrollToSection(packagingRef)} className="sdcp-sidebar-heading">Packaging Standards</button></li>
          <li><button onClick={() => scrollToSection(deliveryAttemptRef)} className="sdcp-sidebar-heading">Delivery Attempt Policy</button></li>
          <li><button onClick={() => scrollToSection(restrictedItemsRef)} className="sdcp-sidebar-heading">Restricted Items</button></li>
          <li><button onClick={() => scrollToSection(cancellationsRef)} className="sdcp-sidebar-heading">Cancellations & Modifications</button></li>
          <li><button onClick={() => scrollToSection(delayedDeliveriesRef)} className="sdcp-sidebar-heading">Delayed Deliveries</button></li>
          <li><button onClick={() => scrollToSection(refundPolicyRef)} className="sdcp-sidebar-heading">Refund & Compensation</button></li>
          <li><button onClick={() => scrollToSection(customerSupportRef)} className="sdcp-sidebar-heading">Customer Support</button></li>
        </ul>
      </div>

      <div className="sdcp-content">
        <h1 className="sdcp-heading">Shipping, Delivery & Cancellation Policy</h1>
        <p className="sdcp-intro">
          At TURTU, we prioritize your convenience and satisfaction. Our shipping and delivery policy ensures a seamless and transparent process for all your orders. Please read the following guidelines carefully:
        </p>

        <div ref={deliveryAreasRef} className="sdcp-section">
          <h2 className="sdcp-subheading">1. Delivery Areas</h2>
          <ul className="sdcp-list">
            <li>We currently aim to operate in Tier 2 cities across India.</li>
            <li>Deliveries are limited to city limits to ensure quick and reliable service.</li>
            <li>On customer demand, we do serve in city outskirts also if the customer agrees to pay the charges.</li>
          </ul>
        </div>

        <div ref={deliveryTimingsRef} className="sdcp-section">
          <h2 className="sdcp-subheading">2. Delivery Timings</h2>
          <ul className="sdcp-list">
            <li><strong>Operating Hours:</strong> 7:00 am to 12:00 am, Monday to Sunday.</li>
            <li>Delivery timelines vary depending on the type of service:</li>
            <ul className="sdcp-inner-list">
              <li>Food Parcels: Delivered within 30-60 minutes.</li>
              <li>Documents, Flowers, Gifts, and Small Packages: Delivered within 1-2 hours.</li>
              <li>Special Services (e.g., Cake Delivery): Delivered within 1-3 hours, depending on customization requirements.</li>
            </ul>
          </ul>
        </div>

        <div ref={deliveryChargesRef} className="sdcp-section">
          <h2 className="sdcp-subheading">3. Delivery Charges</h2>
          <p className="sdcp-paragraph">
            Delivery fees are calculated based on distance and item type. Any applicable delivery charges will be displayed at checkout before you confirm your order.
          </p>
        </div>

        <div ref={orderTrackingRef} className="sdcp-section">
          <h2 className="sdcp-subheading">4. Order Tracking</h2>
          <ul className="sdcp-list">
            <li>Once your order is confirmed, you will receive an update via SMS or email with details of your delivery executive.</li>
            <li>Real-time tracking will be made available once our tech platform is operational.</li>
          </ul>
        </div>

        <div ref={packagingRef} className="sdcp-section">
          <h2 className="sdcp-subheading">5. Packaging Standards</h2>
          <ul className="sdcp-list">
            <li>Items are packed securely to ensure they reach you in perfect condition.</li>
            <li>For fragile items (e.g., cakes or delicate gifts), additional care is taken during transport.</li>
          </ul>
        </div>

        <div ref={deliveryAttemptRef} className="sdcp-section">
          <h2 className="sdcp-subheading">6. Delivery Attempt Policy</h2>
          <ul className="sdcp-list">
            <li>If the recipient is unavailable at the provided address during the first attempt:</li>
            <ul className="sdcp-inner-list">
              <li>A second delivery attempt will be made upon request (additional charges may apply).</li>
              <li>If undelivered after the second attempt, the order will be returned to the sender, and no refund will be issued.</li>
            </ul>
          </ul>
        </div>

        <div ref={restrictedItemsRef} className="sdcp-section">
          <h2 className="sdcp-subheading">7. Restricted Items</h2>
          <p className="sdcp-paragraph">
            TURTU reserves the right to refuse the shipment of prohibited or restricted items, including hazardous materials and illegal goods.
          </p>
        </div>

        <div ref={cancellationsRef} className="sdcp-section">
          <h2 className="sdcp-subheading">8. Cancellations and Modifications</h2>
          <ul className="sdcp-list">
            <li>Cancellations or modifications to an order are allowed within 10 minutes of order placement.</li>
            <li>Once the delivery process has started, no cancellations or changes can be made.</li>
          </ul>
        </div>

        <div ref={delayedDeliveriesRef} className="sdcp-section">
          <h2 className="sdcp-subheading">9. Delayed Deliveries</h2>
          <ul className="sdcp-list">
            <li>TURTU strives to deliver on time, but delays may occur due to factors beyond our control, such as:</li>
            <ul className="sdcp-inner-list">
              <li>Bad weather</li>
              <li>Traffic congestion</li>
              <li>Unforeseen technical issues</li>
            </ul>
            <li>In such cases, our team will keep you informed and provide assistance.</li>
          </ul>
        </div>

        <div ref={refundPolicyRef} className="sdcp-section">
          <h2 className="sdcp-subheading">10. Refund and Compensation Policy</h2>
          <ul className="sdcp-list">
            <li>Refunds or compensation will only be considered in cases of:</li>
            <ul className="sdcp-inner-list">
              <li>Non-delivery of the item.</li>
              <li>Delivery of damaged or incomplete items (evidence required).</li>
              <li>Incorrect delivery.</li>
            </ul>
            <li>For eligible cases, please contact our customer support within 24 hours of delivery.</li>
          </ul>
        </div>
        <div ref={customerSupportRef} className="sdcp-section">
          <h2 className="sdcp-subheading">11. Customer Support</h2>
          <ul className="sdcp-list">
            <li>For any queries or issues related to shipping and delivery, please reach out to us at:  </li>
            <ul className="sdcp-inner-list">
              <li>- Phone: +91 8747858317  </li>
              <li>- Email: tuertuservices@gmail.om</li>
             
            </ul>
            <p> <strong style={{color:'black'}}>By placing an order with TURTU, you agree to abide by this Shipping and Delivery Policy.
                 We thank you for choosing TURTU and look forward to serving you!</strong></p>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
