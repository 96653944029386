import axios from 'axios';


export const fetchSuggestions = async (input) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user/autocomplete`, {
        params: { input },
      });
      return response|| [];
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      throw error; // Rethrow the error for handling in the calling component
    }
  };