import React, { useEffect, useState } from 'react';
import './OrderProcess.css'; 
import PlaceOrder from '../../Images/img/PlaceOrder.webp';
import AssignDeliveryHero from '../../Images/img/AssignedDeliveryHero.webp';
import DeliveredOrder from '../../Images/img/OrderDelivered.webp';

const OrderProcess = () => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep(prevStep => (prevStep + 1) % 3);  
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
    <div className='order-process-header text-center'>
      <h2>How it works</h2>
    </div>
    <div className="order-process-container">
  <div className="step" style={{ '--delay': '0s' }}>
    <img src={PlaceOrder} alt="Place Your Order" />
    <h3>Book a Delivery or Pick-up</h3>
  </div>

  <div className="step" style={{ '--delay': '1s' }}>
    <img src={AssignDeliveryHero} alt="Delivery Hero Assigned" />
    <h3>Quick Pickup</h3>
  </div>
  <div className="step" style={{ '--delay': '2s' }}>
    <img src={DeliveredOrder} alt="Order Delivered" />
    <h3>Fast & Secure Delivery</h3>
  </div>
</div>
</>
  );
};

export default OrderProcess;
