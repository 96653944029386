
        import React, { useEffect } from "react";
        import { Link } from "react-router-dom";
        // import "./PrivacyPolicy.css";
        
        const TermsAndConditions = () => {
          useEffect(() => {
            // Smooth scroll for anchor links
            const links = document.querySelectorAll(".sidebar-link");
            links.forEach((link) => {
              link.addEventListener("click", (e) => {
                e.preventDefault();
                const targetId = e.target.getAttribute("href").slice(1);
                document.getElementById(targetId).scrollIntoView({
                  behavior: "smooth",
                });
              });
            });
          }, []);
        
          return (
            <div className="privacy-policy-container">
              <div className="sidebar">
                <h2 className="sidebar-heading">Terms and Conditions Sections</h2>
                <ul>
                  <li><a href="#company-information" className="sidebar-link">Company Information</a></li>
                  <li><a href="#services-offered" className="sidebar-link">Services Offered</a></li>
                  <li><a href="#eligibility" className="sidebar-link">Eligibility</a></li>
                  <li><a href="#user-obligations" className="sidebar-link">User Obligations</a></li>
                  <li><a href="#account-registration" className="sidebar-link">Account Registration</a></li>
                  <li><a href="#payment-terms" className="sidebar-link">Payment Terms</a></li>
                  <li><a href="#order-n-delivery" className="sidebar-link">Order and Delivery</a></li>
                  <li><a href="#refund-policy" className="sidebar-link">Refund Policy</a></li>
                  <li><a href="#privacy-policy" className="sidebar-link">Privacy Policy</a></li>
                  <li><a href="#limitation-of-liability" className="sidebar-link">Limitation of Liability</a></li>
                  <li><a href="#governing-law" className="sidebar-link">Governing Law</a></li>
                  <li><a href="#amendments" className="sidebar-link">Amendments</a></li>
                  <li><a href="#termination" className="sidebar-link">Termination</a></li>
                  <li><a href="#contact-us" className="sidebar-link">Contact Us</a></li>
                  <li><a href="#service-specific-terms" className="sidebar-link">Service-Specific Terms</a></li>
                  <li><a href="#dispute-resolution" className="sidebar-link">Dispute Resolution</a></li>
                  <li><a href="#serverability" className="sidebar-link">Severability</a></li>
                  <li><a href="#entire-agreement" className="sidebar-link">Entire Agreement</a></li>
                </ul>
              </div>
        
              <div className="privacy-policy-content">
                <h1>Terms and Conditions</h1>
                <p className="date">Effective Date: December 5, 2024</p>
                
                <section id="company-information" className="section">

                  <h2>1. Company Information</h2>
                  <p>Welcome to Turtu India LLP. These Terms and Conditions ("Terms") govern your use of our services ("Services") offered via our website, https://turtu.in ("Website").
                     By accessing or using our Services, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please do not use our Services.</p>
                     <ul>
                      <li> <strong>Company Name:</strong> Turtu India LLP</li> <br />
                      <li><strong>Website URL: </strong>https://turtu.in</li> <br />
                      <li><strong>Contact Information: </strong>turtuservices@gmail.com</li>
                     </ul>
                </section>
        
                <section id="services-offered" className="section">
                  <h2>2. Services Offered</h2>
                  <p>Turtu India LLP provides a variety of on-demand delivery services to cater to your needs.
                     Our offerings include, but are not limited to:</p>
                  <ul>
                    <li><strong>	Food Delivery: </strong>
                    We partner with local restaurants and eateries to bring you a wide range of cuisines right to your doorstep.
                     Enjoy the convenience of having your favorite meals delivered quickly and efficiently.
                    </li>
                    <li><strong>	Organic Products Delivery: </strong> 
                    Our Organic Products delivery service ensures that you receive fresh and quality products from Trusted stores.
                     Whether you need daily essentials or specialty items, we’ve got you covered.
                  </li>
                    <li><strong>	Medicine Delivery: </strong> 
                    We collaborate with licensed pharmacies to deliver prescription and over-the-counter medications. Your health and 
                    safety are our top priorities, and we ensure that your medications are delivered promptly.
                  </li>
                    <li><strong>	Courier Services: </strong> 
                    Our courier service facilitates the pick-up and drop-off of packages within specified areas.
                     We handle your parcels with care to ensure they reach their destination safely and on time.
                    </li>
                    <li><strong>	Pick and Drop Services: </strong> 
                    Whether it’s picking up important documents or dropping off items at a specific location,
                     our pick and drop services are designed to make your life easier.
                    </li>
                    <li><strong>	Cake Delivery Service:  </strong> 
                    Celebrate your special occasions with our premium cake delivery service. We offer a variety
                     of cakes, including custom designs, to make your celebrations memorable.
                    </li>
                  </ul>
                </section>
        
                <section id="eligibility" className="section">
                  <h2>3. Eligibility</h2>
                  <p>To use our Services, you must meet the following criteria:</p>
                  <ul>
                    <li><strong>Age Requirement:  </strong> 
                    You must be at least 18 years old or have reached the age of majority in your jurisdiction. By using our Services, you represent and warrant that you meet this requirement.
                    </li>
                    <li><strong>Legal Capacity:  </strong> 
                    You must have the legal capacity to enter into a binding contract. If you are using our Services on behalf of a business entity, you represent
                     and warrant that you have the authority to bind that entity to these Terms.
        
                  </li>
                  </ul>
                </section>
        
                <section id="user-obligations" className="section">
                  <h2>4. User Obligations</h2>
                  <p>By using our Services, you agree to the following obligations:</p>
                  <ul>
                    <li><strong>Accurate Information:   </strong> 
                    Provide accurate and complete information when creating an account and placing orders.
                     You are responsible for ensuring that your account information is up-to-date.
        
                    </li>
                    <li><strong>	Account Security:  </strong> 
                    Keep your account information secure and confidential. You are responsible for all activities that occur under your account.
                  </li>
                    <li><strong>	Lawful Use:  </strong>  
                    Use our Services only for lawful purposes and in a manner consistent with these Terms. Do not engage in any activity
                     that could harm or disrupt our operations, infrastructure, or reputation.

                  </li>
                    <li><strong>Respect Privacy: </strong> 
                    Respect the privacy and personal information of other users. Do not engage in any activities
                     that infringe on the privacy rights of others.

                     </li>
                   
                  </ul>
                </section>
        
                <section id="account-registration" className="section">
                  <h2>5. Account Registration</h2>
                  <p>To access certain features of our Services, you may need to register for an account. When registering, you agree to:</p>

                  <ul>
                    <li>
                    <strong>	Provide Accurate Information: </strong>
                    Provide accurate, current, and complete information during the registration process. Inaccurate or incomplete information
                     may result in the suspension or termination of your account.
                    </li>
                    <li>
                    <strong>	Update Information: </strong>
                    Maintain and promptly update your information to keep it accurate, current, and complete.
                     This includes your contact details and payment information.
                    </li>
                    <li>
                    <strong>Account Security:  </strong>
                    Protect the security of your password and accept responsibility for all activities that occur under your account.
                     Notify us immediately of any unauthorized use or breach of security.
                    </li>
                  </ul>
                </section>
        
                <section id="payment-terms" className="section">
                  <h2>6. Payment Terms</h2>
                  <ul>
                    <li><strong> Payment Methods:   </strong>
                    We accept various payment methods, including credit cards, debit cards, UPI, and other online payment methods. By providing payment information, 
                    you represent and warrant that you are authorized to use the designated payment method.
                    </li>
                    <li><strong> Billing Information:  </strong>
                    Ensure that your billing information is accurate and up-to-date. You agree to pay all charges incurred by your account,
                     including applicable taxes and fees.
                    </li>
                    <li><strong> Payment Processing:   </strong>
                    All payments are subject to validation checks and authorization by your payment card issuer. If your payment method is declined, 
                    we reserve the right to cancel your order or request an alternative payment method.
                    </li>
                    <li><strong> Payment Disputes:  </strong>
                    If you believe there has been an error in billing, please contact our customer support team immediately.
                     We will investigate and, if necessary, make any corrections.
                    </li>
                  </ul>
                </section>
        
                <section id="order-n-delivery" className="section">
                  <h2>7. Order and Delivery</h2>
                  <ul>
                    <li> <strong>Order Placement: </strong> Once an order is placed, it cannot be canceled or modified unless otherwise permitted by Turtu India LLP. Please review your order carefully before confirming.</li>
                    <li>  <strong>Delivery Times: </strong> Delivery times are estimates and may vary due to external factors such as traffic, weather conditions, and availability of delivery personnel. We strive to deliver your orders as quickly as possible.</li>
                     <li> <strong>Order Tracking: </strong> You may track your order status through our Website or Email. We will provide updates on the progress of your delivery.</li> 
                      <li>  <strong>Failed Deliveries:  </strong> If delivery is attempted but fails due to incorrect address, absence of recipient, or other reasons within your control, additional delivery charges may apply.</li>
                     
                  </ul>
                </section>
                <section id="refund-policy" className="section">
                  <h2> 8. Refund Policy</h2>
                  <ul>
                    <li>
                      <strong>Eligibility: </strong>
                      Refunds may be issued at the discretion of Turtu India LLP in cases of order discrepancies, late deliveries, or other valid reasons.
                       Refund requests must be made on the day of your order.
                    </li>
                    <li>
                      <strong>Refund Process:  </strong>
                      To request a refund, please contact our customer support team at turtuservices@gmail.com with your order details and reason for the request.
                       We will review your request and respond within 3 working days.
                    </li>
                    <li>
                      <strong>Refund Method: </strong>
                      Refunds will be issued to the original payment method used for the order. Please allow 7 working
                      days for the refund to be processed and reflected in your account.
                    </li>
                    <li>
                      <strong>Non-Refundable Items:  </strong>
                       Certain items, such as perishable goods and customized products, may not be eligible for refunds.
                       We will inform you of any non-refundable items at the time of purchase.
                    </li>
                  </ul>
        
                </section>
        
                <section id="privacy-policy" className="section">
                  <h2> 9. Privacy Policy</h2>
                  <p>We are committed to protecting your privacy. Your personal data is handled securely and is not shared with third parties without your consent. For more details, please refer to our <Link to="/privacy-policy">Privacy Policy.</Link> </p>
                </section>
                <section id="limitation-of-liability" className="section">
                  <h2>10. Limitation of Liability</h2>
                   <ul>
        
                   <li>  <strong>	Disclaimer: </strong> 
                   Turtu India LLP is not liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our Services. This includes, but is not limited to, loss of profits, data, or goodwill.</li> 
        
                   <li> <strong> Maximum Liability: </strong>
                   Our total liability to you for any claims arising from your use of our Services is limited to the amount paid by you for the specific order in question.
                    </li>  
        
                  <li> <strong>No Warranty: </strong> 
                  Our Services are provided "as is" and "as available" without any warranties, express or implied. We do not guarantee the accuracy, completeness, or reliability of our Services.</li>  
                   </ul>
        
                </section>
                <section id="governing-law" className="section">

                  <h2>11. Governing Law</h2>
                  <ul>
                 <li>
                  <strong>
                  Jurisdiction: 
                  </strong>
                  These Terms are governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Karnataka.
                 </li>
                 <li>
                  <strong>
                  Legal Compliance: 
                  </strong>
                  You agree to comply with all applicable laws and regulations when using our Services. This includes, but is not limited to, data protection laws, consumer protection laws, and intellectual property laws.
                  </li>
                  </ul>
                </section>
                <section id="amendments" className="section">
                  <h2>12. Amendments</h2>
                  <ul>
                 <li>
                  <strong>
                  Right to Modify: 
                  </strong>
                  We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our Website and App. It is your responsibility to review these Terms periodically.</li>
                 <li>
                  <strong>
                  Notification of Changes: 
                  </strong>
                  We will notify you of any significant changes to these Terms via email or through our Website and App. Your continued use of our Services after any modifications indicate your acceptance of the revised Terms. </li>
                </ul>
                </section>
                <section id="termination" className="section">
                  <h2>13. Termination</h2>
                  <ul>
                 <li>
                  <strong>
                  Account Suspension: 
                  </strong>
                  We reserve the right to terminate or suspend your account and access to our Services at our sole discretion, without notice or liability, for conduct that we believe violates these Terms or is harmful to our business interests. </li>
                 <li>
                  <strong>
                  	Effect of Termination: 
                  </strong>
                  Upon termination, your right to use our Services will immediately cease. All provisions of these Terms that by their nature should survive termination shall survive, including but not limited to, ownership provisions, warranty disclaimers, indemnity, and limitations of liability. </li>
               </ul>
                </section>
                <section id="contact-us" className="section">
                  <h2>14. Contact Us</h2>
                  <p>If you have any questions or concerns about these Terms or our Services, please contact us at:</p>
                 <strong>Turtu India LLP</strong>  <br />
                 <strong>Email: turtuservices@gmail.com</strong>   <br />
                  <strong>Website: https://turtu.in</strong> 

                </section>
                <section id="service-specific-terms" className="section">
                  <h2>15. Service-Specific Terms</h2>
                  <h4>15.1 Food Delivery</h4>
                  <ul>
                  <li>
                   <strong>Order Placement: </strong> 
                   Ensure that the delivery address and contact information provided are accurate. Incorrect information may lead to delays or unsuccessful deliveries.
                  </li>
                  <li>

                   <strong>Quality and Safety: </strong> 
                   We partner with reputable restaurants to ensure the quality and safety of the food delivered. If you encounter any issues with the quality of your food,
                  please contact our customer support team immediately.
                  </li>

                    <h4>15.2 Organic Products Delivery</h4>
                  <li>
                   <strong>Product Availability: </strong> 
                   Product availability may vary based on stock and supplier availability. If a product is unavailable, we will notify you and provide alternatives or process a refund.
                  </li>
                  <li>
                   <strong>Freshness Guarantee: </strong> 
                   We strive to deliver fresh and high-quality products. If you receive any items that do not meet your expectations, please contact our customer support team for assistance.
                  </li>
                    <h4>15.3 Medicine Delivery</h4>
                  <li>

                   <strong>Prescription Requirement: </strong> 
                   Product availability may vary based on stock and supplier availability. If a product is unavailable, we will notify you and provide alternatives or process a refund.
                  </li>
                  <li>

                   <strong>Medication Safety: </strong> 
                   Follow all instructions provided with your medications. If you have any questions or concerns about your medications, consult your healthcare provider
                  </li>

                    <h4>15.4 Courier Services</h4>

                  <li>

                   <strong>Package Guidelines: </strong> 
                   Ensure that your packages comply with our guidelines regarding size, weight, and prohibited items. Non-compliant packages may be refused for delivery.
                  </li>
                  <li>

                   <strong>	Delivery Liability: </strong> 
                   We take precautions to ensure the safe delivery of your packages. However, we are not liable for any damage or loss that occurs during transit unless caused by our negligence.
                  </li>

                  <h4>15.5 Pick and Drop Services</h4>
                  <li>

                   <strong>Service Areas: </strong> 
                   Our pick and drop services are available within specified areas. Check our Website or App for service availability in your area.
                  </li>
                  <li>

                   <strong>Timeliness: </strong> 
                   : Provide accurate pick-up and drop-off addresses and contact information to ensure timely service. We are not responsible for delays caused by incorrect information.
                  </li>

                  <h4>15.6 Cake Delivery Service</h4>
                  <li>

                   <strong>Customization Requests</strong> 
                   Custom cake orders must be placed in advance. Provide detailed instructions and allow sufficient time for preparation and delivery.
                  </li>
                  <li>

                   <strong>Allergy Information: </strong>
                   Inform us of any allergies or dietary restrictions when placing your order. We will make reasonable efforts to accommodate your requests 
                  </li>
                 </ul>
                </section>

                <section id="dispute-resolution" className="section">
                  <h2>16. Dispute Resolution</h2>
                 <ul>
                  <li><strong>Informal Resolution: </strong>We encourage you to contact us directly to resolve any disputes or issues. Most concerns can be resolved quickly and to your satisfaction.</li>
                  <li><strong>Arbitration: </strong>If we cannot resolve a dispute through informal negotiation, any claim, dispute, or controversy arising out of or in connection with these Terms shall be settled by binding arbitration. The arbitration shall be conducted in accordance with the rules of the Indian Council of Arbitration (ICA)
                  .</li>
                  <li><strong>Governing Law: </strong>The arbitration and any related proceedings shall be governed by the laws of India.</li>
                 </ul>
                </section>

                <section id="serverability" className="section">
                <h2>17. Severability</h2>
                <p>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable. The invalid or unenforceable provision will be replaced by a valid, enforceable provision that closely matches the intent of the original provision.</p>
                </section>
                <section id="entire-agreement" className="section">
                <h2>18. Entire Agreement</h2>
                <p>These Terms constitute the entire agreement between you and Turtu India LLP regarding your use of our Services. Any prior agreements, understandings, or representations are superseded by these Terms.</p>
                <p>By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. Thank you for choosing Turtu India LLP.</p>
                </section>

              </div>
            </div>
          );
        };
        
        export default TermsAndConditions;
        