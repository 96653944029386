

import React from 'react';
import './Ourteam.css';
import Header from '../Header/Header';

const TeamComponent = () => {
  const teamData = [
    { name: 'Anil Chavan ', role: 'Founder & CEO', img: require('./../../Images/img/Anil.webp') },
    { name: 'Prathamesh Kinayekar', role: 'Back-end Developer', img: require('./../../Images/img/prathamesh.webp') },
    { name: 'Mallikarjun Mudapaki', role: 'Front-end Developer', img: require('./../../Images/img/mallikarjun.webp') },
    { name: 'Sneha Ekbote', role: 'DevOps Engineer', img: require('./../../Images/img/sneha.webp') },
    { name: 'Jayashree Patil', role: 'QA Engineer', img: require('./../../Images/img/jayashree.webp') },
    { name: 'Avantika Patil', role: 'Data Analyst', img: require('./../../Images/img/avantika.webp') },
    { name: 'Saikumar Badiger', role: 'Human Resources(HR)', img: require('./../../Images/img/Saikumar.webp') },
    { name: 'Mallesh Nashi', role: 'Fleet Manager', img: require('./../../Images/img/Mallesh.webp') },
  ];

  return (
    <>
      <Header />
      <div className='ourteam-main-container'>
        <h2 className="ourteam-title">Meet The <span className="ourteam-highlight">Team</span></h2>
        <div className="ourteam-container">
          <div className="ourteam-leadership">
            <div className="ourteam-card">
              <img src={teamData[0].img} alt={teamData[0].name} className="ourteam-image" />
              <div className="ourteam-name">{teamData[0].name}</div>
              <div className="ourteam-role">{teamData[0].role}</div>
            </div>
          </div>
          <div className="ourteam-team">
            {teamData.slice(1,5).map((member, index) => (
              <div className="ourteam-card" key={index}>
                <img src={member.img} alt={member.name} className="ourteam-image" />
                <div className="ourteam-name">{member.name}</div>
                <div className="ourteam-role">{member.role}</div>
              </div>
            ))}
          </div>
          <div className="ourteam-team">
            {teamData.slice(5).map((member, index) => (
              <div className="ourteam-card" key={index}>
                <img src={member.img} alt={member.name} className="ourteam-image" />
                <div className="ourteam-name">{member.name}</div>
                <div className="ourteam-role">{member.role}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamComponent;

