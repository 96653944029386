
import React from 'react';
import { HiArrowCircleRight } from "react-icons/hi";
import './UserOrderService.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaWhatsapp, FaPhone } from "react-icons/fa";

const Services = (props) => {

  const navigate = useNavigate();

  const handleCardClick = () => {
    // if (props.path) {
      // navigate(props.path);
    // } else { 
      props.onClick();
    // }
  };

  const handleChoice = (choice) => {
    if (choice === 'whatsapp') {
      const whatsappUrl = `https://wa.me/${props.phoneNumber}?text=${encodeURIComponent(props.whatsappMessage)}`;
      window.open(whatsappUrl, '_blank');
    } else if (choice === 'call') {
      window.location.href = `tel:${props.phoneNumber}`;
    }
  };

  

  return (
    <div className="service-card" onClick={handleCardClick} style={{ backgroundColor: props.color }}>
      <div className="service-header">
        <h4 className='service-header-text'>{props.heading}</h4>
      </div>
      <div className='service-lower-container'>
        <div className="service-navigate-btn">
          {/* {props.path ? (
            <Link to={props.path} className="service-navigate-btn">
              <div className="navigateIcon">
                <HiArrowCircleRight />
              </div>
            </Link>
          ) : ( */}
            <div className="navigateIcon">
              <HiArrowCircleRight />
            </div>
          {/* )} */}
        </div>
        {props.showPopup && (
          <div className="popup-menu">
            <button className='s-popupbtn-whatsapp' onClick={() => handleChoice('whatsapp')}><FaWhatsapp size={25} /></button>
            <button className='s-popupbtn-call' onClick={() => handleChoice('call')}><FaPhone size={25} /></button>
          </div>
        )}
        <div className="service-image">
          <img className='Service-img' src={props.img} alt="Service" />
        </div>
      </div>
    </div>
  );
}

export default Services;
