
import React, { useEffect } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    // Smooth scroll for anchor links
    const links = document.querySelectorAll(".sidebar-link");
    links.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        const targetId = e.target.getAttribute("href").slice(1);
        document.getElementById(targetId).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    
    <div className="privacy-policy-container">
      <div className="sidebar">
        <h2 className="sidebar-heading">Privacy Policy Sections</h2>
        <ul>
          <li><a href="#introduction" className="sidebar-link">Introduction</a></li>
          <li><a href="#information-we-collect" className="sidebar-link">Information We Collect</a></li>
          <li><a href="#how-we-use-your-information" className="sidebar-link">How We Use Your Information</a></li>
          <li><a href="#how-we-share-your-information" className="sidebar-link">How We Share Your Information</a></li>
          <li><a href="#data-security" className="sidebar-link">Data Security</a></li>
          <li><a href="#data-retention" className="sidebar-link">Data Retention</a></li>
          <li><a href="#your-rights" className="sidebar-link">Your Rights</a></li>
          <li><a href="#childrens-policy" className="sidebar-link">Children's Privacy</a></li>
          <li><a href="#changes-to-this-Privacy-Policy" className="sidebar-link">Changes to this Privacy Policy</a></li>
          <li><a href="#cookies-tracking" className="sidebar-link">Cookies & Tracking Technologies</a></li>
          <li><a href="#contact-us" className="sidebar-link">Contact Us</a></li>
        </ul>
      </div>

      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p className="date">Effective Date: December 5, 2024</p>

        <section id="introduction" className="section">
          <h2>1. Introduction</h2>
          <p>At Turtu India LLP, your privacy is very important to us. We understand the significance of your personal information
             and are committed to protecting it. This Privacy Policy outlines the information we collect, how we use it, and how we 
             keep it secure. It also explains your rights regarding your data and how we handle it. We provide on-demand delivery services
              for food, medicine, groceries, and other items, as well as pick-up and drop services. We are also committed to maintaining transparency
               about the collection, use, and sharing of personal information.</p>
        </section>

        <section id="information-we-collect" className="section">
          <h2>2. Information We Collect</h2>
          <p>We collect various types of information to provide and improve our services to you, including:</p>
          <ul>
            <li><strong>Personal Information:</strong>
             When you use our services, create an account, place an order, or contact us, we collect personal information such as: <br />
            - Name <br />
            - Email address <br />
            - Phone number <br />
            - Delivery address <br />
            - Payment details (credit card information, payment gateway details)
            </li>
            <li><strong>Usage Data:</strong> We collect data on how you interact with our website or app, such as: <br />
          - IP address <br />
          - Browser type and version <br />
          - Device information (e.g., model, operating system)< br /> 
          - Pages visited, time spent, and other usage data
          </li>
            <li><strong>Location Data:</strong> To provide our delivery and pick-up services, we collect your location data. This may include: <br />
          - GPS coordinates for real-time location tracking during deliveries <br />
          - Location history (when accessing the app or website)
          </li>
            <li><strong>Cookies:</strong> We use cookies, web beacons, and similar tracking technologies to track your activity and collect data to improve your user experience. You can control 
            your cookie preferences through your browser settings.</li>
          </ul>
        </section>

        <section id="how-we-use-your-information" className="section">
          <h2>3. How We Use Your Information</h2>
          <p>We use the information we collect for various purposes, including:</p>
          <ul>
            <li><strong>Providing Services: </strong> <br />
            - To process orders, schedule deliveries, and ensure timely pick-up and drop-off services. <br />
            - To offer customer support, resolve issues, and address your inquiries. <br />
            - To notify you about order status, updates, promotions, and other information related to our services.

            </li>
            <li><strong>Improving Our Services: </strong> <br />
            - To personalize your experience and optimize the user interface. <br />
            - To analyze user behavior and enhance features for better functionality. <br />
            - To improve service quality, including delivery speed, accuracy, and overall satisfaction.

          </li>
            <li><strong>Communication: </strong>  <br />
            - To send you updates, promotional offers, and newsletters, based on your preferences. <br />
            - To notify you of new services, features, or changes to our terms and conditions.

          </li>
            <li><strong>Legal Compliance: </strong> <br />
            - To comply with legal obligations, such as fraud prevention and meeting regulatory 
             </li>
            <li><strong>requirements. </strong> <br />
            - To protect the rights, property, and safety of Turtu India LLP, our users, or third parties.
             </li>
          </ul>
        </section>

        <section id="how-we-share-your-information" className="section">
          <h2>4. How We Share Your Information</h2>
          <p>We do not share your personal data except in the following cases:</p>
          <ul>
            <li><strong>With Service Providers:  </strong> <br />
            We may share your data with trusted third-party service providers who help us operate our business, such as: <br />
            - Payment processors<br />
            - Delivery partners and logistics providers<br />
            - IT service providers and cloud storage providers

            </li>
            <li><strong>Business Transfers:  </strong> <br />
            In the event of a merger, acquisition, or sale of assets, your personal data may be transferred as part of the transaction. 
            We will notify you if your data is transferred and provide you with choices regarding your information.

          </li>
            <li><strong>Legal Compliance:  </strong>  <br />
            We may disclose your information to law enforcement authorities or other parties when: <br />
            - Required by law
            - Necessary to protect the rights, property, or safety of Turtu India LLP, our users, or others <br />
            - To comply with a legal process, such as a court order or subpoena <br />


          </li>
            <li><strong>With Your Consent:  </strong> <br />
            We may share your data with third parties for purposes not covered under this policy if you provide explicit consent.
             </li>
           
          </ul>
        </section>

        <section id="data-security" className="section">
          <h2>5. Data Security</h2>
          <p>We implement reasonable technical, administrative, and physical security measures to protect your personal information.
             These measures include encryption, firewalls, and secure servers. However, no method of transmission over the internet or
              electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we
               cannot guarantee its absolute security.</p>
        </section>

        <section id="data-retention" className="section">
          <h2>6. Data Retention</h2>
          <p>We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer
             retention period is required or permitted by law.</p>
        </section>

        <section id="your-rights" className="section">
          <h2>7. Your Data Protection Rights</h2>
          <p>Depending on your jurisdiction, you may have the following rights regarding your personal data:</p>
          <ul>
            <li> - <strong>Access: </strong>You can request information about the personal data we hold about you. </li>
            <li> - <strong>Correction:</strong> You can update or correct any inaccurate or incomplete personal data.</li>
             <li>- <strong> Deletion:</strong>  You can request the deletion of your personal data.</li> 
              <li> - <strong> Objection: </strong> You can object to the processing of your personal data in certain circumstances.</li>
             <li> -  <strong> Data Portability:</strong> You can request a copy of your personal data in a structured, commonly used, and machine-readable format.

            </li>
          </ul>
        </section>
        <section id="childrens-policy" className="section">
          <h2> 8. Children's Privacy</h2>
          <p>
          Our services are not intended for children under the age of 18, and we do not knowingly collect personal information from children. If we become aware that we have
           inadvertently collected personal data from a child, we will take steps to delete the information.
          </p>

        </section>

        <section id="changes-to-this-Privacy-Policy" className="section">
          <h2> 9. Changes to this Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. When we do, we will post the revised policy on our website and update
             the 'Effective Date' at the top. We encourage you to review this policy periodically to stay informed about how we are protecting your personal data.</p>
        </section>
        <section id="cookies-tracking" className="section">
          <h2>8. Cookies and Tracking Technologies</h2>
           <ul>

           <li>  <strong>Cookie Usage</strong> 
             We use cookies to enhance your experience, analyze trends, and gather demographic information about our user base. Cookies help us understand how you interact with our website and improve its functionality.</li> <br />

           <li> <strong> Managing Cookies</strong>
            You can manage your cookie preferences through your browser settings. Most browsers allow you to control cookies, including blocking or deleting them. However, disabling cookies may affect the functionality of our services.</li>  <br />

          <li> <strong>Tracking Technologies</strong> 
            In addition to cookies, we use other tracking technologies, such as web beacons and pixels, to gather information about your browsing behavior. These technologies help us measure the effectiveness of our marketing campaigns and improve our services.</li>  
           </ul>

        </section>
        <section id="contact-us" className="section">
          <h2>10.Contact Us</h2>
          <p>If you have any questions about this Privacy Policy or how we handle your personal data, please contact us at:</p> <br />
        - Email: turtuservices@gmail.com <br />
        - Phone: +91 8747858317<br />
        - Address: TURTU , Plot No. 476, Flat No. 2, Second Floor,
         Shivbasav Nagar,Opposite to Siddharameshwar Science P U collage, Belagavi , Karnataka

        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
